import React from "react";
import "./merchItem.css";

const MerchItem = ({ availability, merchPrice, merchTitle, url }) => {
  return (
    <div className="merchItemBox">
      <div className="priceBoxMobile">
        <div style={{ height: "100%" }}>
          <div style={{ whiteSpace: "nowrap" }}>{merchTitle}</div>
          {availability ? (
            <div>{merchPrice}</div>
          ) : (
            <>
              <div style={{ textDecoration: "line-through" }}>{merchPrice}</div>
              <div>Out of Stock</div>
            </>
          )}
        </div>
      </div>
      <div className="imageAndTitle">
        <img src={url} alt="merchImage" className="merchImage" />
      </div>

      <div className="priceBox">
        <div style={{ height: "100%", paddingTop: "8px" }}>
          <div>{merchTitle}</div>
          {availability ? (
            <div>{merchPrice}</div>
          ) : (
            <>
              <div style={{ textDecoration: "line-through" }}>{merchPrice}</div>
              <div>Out of Stock</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MerchItem;
