import React from "react";
import { makeStyles } from "@mui/styles";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const useStyles = makeStyles({
  dropdownOverride: {
    "& .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white !important",
    },
  },
});

const DropDownMenu = ({ setPodcast, dropDownValue, podcastTitlesArr }) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.dropdownOverride}>
      <InputLabel id="demo-simple-select-label" style={{ color: "white" }}>
        Podcasts
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={dropDownValue}
        label="Podcasts"
        onChange={setPodcast}
        className={classes.root}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
      >
        {podcastTitlesArr.map((title, index) => {
          return <MenuItem value={index}>{title}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
export default DropDownMenu;
