import Home from "../pages/home";
import Merch from "../pages/merch";
import Podcasts from "../pages/podcasts";
import Rankings from "../pages/rankings";

const Links = [
  {
    path: "/",
    exact: true,
    component: <Home />,
  },
  {
    path: "/home",
    exact: true,
    component: <Home />,
  },
  {
    path: "/podcasts",
    exact: true,
    component: <Podcasts />,
  },
  {
    path: "/rankings",
    exact: true,
    component: <Rankings />,
  },
  {
    path: "/merch",
    exact: true,
    component: <Merch />,
  },
];

export default Links;
