import React, { useState, useEffect } from "react";
import "./navbarContainer.css";
import NavbarItems from "../../components/navbarItems";
import MenuIcon from "@mui/icons-material/Menu";
import MenuComponent from "../../components/menuComponent";
import { useNavigate } from "react-router-dom";

const NavbarContainer = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const links = ["Podcasts", "Merch", "Rankings"];
  const navigate = useNavigate();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handleLInkClick = (link) => {
    navigate(link.toLowerCase());
    setAnchorEl(null);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={
        scrollPosition > 0
          ? "navbarContainerMaroon"
          : "navbarContainerTransparent"
      }
    >
      <div className="titleContainer">
        <NavbarItems names={"Last Second Podcast"} link="Home" />
      </div>
      <div className="linksContainer">
        {links.map((link) => (
          <NavbarItems key={link} names={link} link={link} />
        ))}
      </div>
      <div className="menuIconContainer">
        <MenuIcon onClick={(event) => setAnchorEl(event.currentTarget)} />
        <MenuComponent
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          handleLinkClick={(link) => handleLInkClick(link)}
          links={links}
        />
      </div>
    </div>
  );
};

export default NavbarContainer;
