import React from "react";
import "./App.css";
import NavbarContainer from "./containers/navbarContainer";
import Links from "./routes";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <div style={{ height: "100%" }}>
      <NavbarContainer />
      <Routes>
        {Links.map((link) => (
          <Route
            key={link.path}
            exact={link.exact}
            path={link.path}
            element={link.component}
          />
        ))}
      </Routes>
    </div>
  );
}

export default App;
