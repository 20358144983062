import ShockTop from '../../assests/photos/shockTop.jfif';
import SamAdams from '../../assests/photos/samAdams.png';
import YoungLing from '../../assests/photos/youngLing.jfif';
import Hillman from '../../assests/photos/hillman.jfif';
import MillerLight from '../../assests/photos/millerLight.jpg';

const JakeBeers = [
	{
		imageUrl: ShockTop,
		rank: 'C',
		name: 'Shock Top',
		description: 'why we ranked it how we did',
		width: '32px',
		companyWebsite: 'https://www.shocktopbeer.com/AgeCheck.aspx?ReturnUrl=%2f',
	},
	{
		imageUrl: SamAdams,
		rank: 'S',
		name: 'Samuel Adams',
		description: 'why we ranked it how we did',
		width: '32px',
		companyWebsite:
			'https://www.samueladams.com/our-beers?https://ad.doubleclick.net/ddm/clk/417695022;219039168;k&gclid=CjwKCAjw14uVBhBEEiwAaufYxzxNxqrCTi0xGCvYACYCNHPRsFlDTfgT7Yxln8gdJveLLXybXT52PBoC0k4QAvD_BwE',
	},
	{
		imageUrl: YoungLing,
		rank: 'C',
		name: 'Yuengling',
		description: 'why we ranked it how we did',
		width: '32px',
		companyWebsite: 'https://www.yuengling.com/',
	},
	{
		imageUrl: Hillman,
		rank: 'A',
		name: 'Hillman Original Lager',
		description: 'why we ranked it how we did',
		width: '32px',
		companyWebsite: 'https://hillmanbeer.com/old-fort/',
	},
	{
		imageUrl: MillerLight,
		rank: 'S',
		name: 'Miller Light',
		description: 'why we ranked it how we did',
		width: '32px',
		companyWebsite:
			'https://www.millerlite.com/av?url=https://www.millerlite.com/buybeeronline?gclid=CjwKCAjwwdWVBhA4EiwAjcYJEG1tA3-TKFVeWjiaMI5JTQxURL37R2p0gSo2WZnqnd4nBat2kywlyRoCISkQAvD_BwE',
	},
];

export default JakeBeers;
