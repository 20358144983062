import React, { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useWindowDimensions from "../../hooks/getWindowDimensions";

const MenuComponent = ({ anchorEl, handleClose, handleLinkClick, links }) => {
  const { width } = useWindowDimensions();
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (width >= 850) {
      handleClose();
    }
  });
  return (
    <Menu
      id="demo-positioned-menu"
      aria-labelledby="demo-positioned-button"
      anchorEl={anchorEl}
      open={open}
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {links.map((linkName) => {
        return (
          <MenuItem key={linkName}>
            <div
              onClick={(event) =>
                handleLinkClick(event.currentTarget.innerText)
              }
              style={{ fontFamily: "Roboto" }}
            >
              {linkName}
            </div>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default MenuComponent;
