import React, { useState, useRef } from 'react';
import DropDownMenu from '../../components/dropDownMenu';
import './podcastContainer.css';
import podcast1 from '../../assests/audio/episode1Bishop.mp3';
import podcast2 from '../../assests/audio/episode2Loyalty.mp3';
import podcast3 from '../../assests/audio/episode3Fear.mp3';
import podcast4 from '../../assests/audio/episode4Undermine.mp3';
import podcast5 from '../../assests/audio/podcast5.mp3';
const PodcastContainer = () => {
	const audioRef = useRef();
	const podcastArr = [podcast1, podcast2, podcast3, podcast4, podcast5];
	const [audio, setAudio] = useState(podcastArr[0]);
	const podcastTitlesArr = [
		'Episode 1: Publicity',
		'Episode 2: Loyalty',
		'Episode 3: Fear',
		'Episode 4: Undermine',
		'Episode 5: Pride',
	];
	const [dropDownValue, setDropDownValue] = useState(0);
	const podcastDescriptionArr = [
		'My friends and I delve into high school football and the trials and tribulations these athletes undergo every year.',
		'My friends and I learn about one of the greatest NBA junkies and his inspirational story.',
		'My friends and I delve into one of the greatest/craziest games in MLB history',
		'My friends and I investigate a controversy invloving the Saints during their Super Bowl run.',
		'My friends and I explore the differnce between playing a sport for fun, and playing a sport for pride.',
	];
	const [podcastDescription, setPodcastDescription] = useState(
		podcastDescriptionArr[0]
	);

	const updateSong = (e) => {
		setPodcastDescription(podcastDescriptionArr[e.target.value]);
		setDropDownValue(e.target.value);
		setAudio(podcastArr[e.target.value]);
		if (audioRef.current) {
			audioRef.current.pause();
			audioRef.current.load();
			audioRef.current.play();
		}
	};

	return (
		<div className='podcastImage'>
			<div className='podcastContainer'>
				<div style={{ alignItems: 'center', display: 'flex' }}>
					<p className='featureText'> Featured Episode </p>{' '}
					<DropDownMenu
						setPodcast={(e) => updateSong(e)}
						dropDownValue={dropDownValue}
						podcastTitlesArr={podcastTitlesArr}
					/>
				</div>

				<p className='featureDescription'> {podcastDescription}</p>
				<audio controls ref={audioRef} className='audioControls'>
					<source src={audio} type='audio/mpeg' />
					Your browser does not support the audio element.
				</audio>
			</div>
		</div>
	);
};

export default PodcastContainer;
