import React from "react";
import "./navbarItems.css";
import { useNavigate } from "react-router-dom";

const NavbarItems = (props) => {
  const navigate = useNavigate();
  return (
    <p
      onClick={() => navigate(props.link.toLowerCase())}
      tabIndex={1}
      style={{ fontFamily: "Roboto", margin: "0px 16px" }}
    >
      {props.names}
    </p>
  );
};

export default NavbarItems;
