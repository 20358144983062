import Mug from '../../assests/LSPMug.png';
import Shirt from '../../assests/LSPShirt.png';
import Hat from '../../assests/LSPHat.png';
import LongSleve from '../../assests/LSPLongSleve.png';
import LSPSocks from '../../assests/LSPSocks.png';
import LSPUnderware from '../../assests/LSPUnderware.png';
const MerchItems = [
	{
		url: LSPUnderware,
		merchTitle: 'Underwear',
		merchPrice: '$34.99',
		availability: false,
	},
	{
		url: Shirt,
		merchTitle: 'T-Shirt',
		merchPrice: '$24.99',
		availability: false,
	},
	{
		url: Hat,
		merchTitle: 'Baseball Hat',
		merchPrice: '$29.99',
		availability: false,
	},

	{
		url: LongSleve,
		merchTitle: 'Long Sleve Shirt',
		merchPrice: '$39.99',
		availability: false,
	},
	{
		url: Mug,
		merchTitle: 'Coffee Mug',
		merchPrice: '$19.99',
		availability: false,
	},
	{
		url: LSPSocks,
		merchTitle: 'Socks',
		merchPrice: '$14.99',
		availability: false,
	},
];

export default MerchItems;
