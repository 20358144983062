import React from 'react';
import './iconComponent.css';

const IconComponent = ({ icon, link }) => {
  return (
    <div className="iconComponent" onClick={() => window.open(link, '_blank')}>
      {icon}
    </div>
  );
};

export default IconComponent;
