import React from 'react';
import BeerItem from '../../components/beerItem';
import './rankingTableContainer.css';

const RankingTableContainer = ({ beerList }) => {
	return (
		<div className='tableContainer'>
			<div className='tableRow'>
				<div tabIndex={1} className='ratingBox box1'>
					S
				</div>
				<div className='beerContainer'>
					{beerList.map((beer) =>
						beer.rank === 'S' ? (
							<BeerItem
								imageUrl={beer.imageUrl}
								name={beer.name}
								companyWebsite={beer.companyWebsite}
							/>
						) : (
							<></>
						)
					)}
				</div>
			</div>
			<div className='tableRow'>
				<div tabIndex={1} className='ratingBox box2'>
					A
				</div>
				<div className='beerContainer'>
					{beerList.map((beer) =>
						beer.rank === 'A' ? (
							<BeerItem
								imageUrl={beer.imageUrl}
								name={beer.name}
								companyWebsite={beer.companyWebsite}
							/>
						) : (
							<></>
						)
					)}
				</div>
			</div>
			<div className='tableRow'>
				<div tabIndex={1} className='ratingBox box3'>
					B
				</div>
				<div className='beerContainer'>
					{beerList.map((beer) =>
						beer.rank === 'B' ? (
							<BeerItem
								imageUrl={beer.imageUrl}
								name={beer.name}
								companyWebsite={beer.companyWebsite}
							/>
						) : (
							<></>
						)
					)}
				</div>
			</div>
			<div className='tableRow'>
				<div tabIndex={1} className='ratingBox box4'>
					C
				</div>
				<div className='beerContainer'>
					{beerList.map((beer) =>
						beer.rank === 'C' ? (
							<BeerItem
								imageUrl={beer.imageUrl}
								name={beer.name}
								companyWebsite={beer.companyWebsite}
							/>
						) : (
							<></>
						)
					)}
				</div>
			</div>
			<div className='tableRow'>
				<div tabIndex={1} className='ratingBox box5'>
					D
				</div>
				<div className='beerContainer'>
					{beerList.map((beer) =>
						beer.rank === 'D' ? (
							<BeerItem
								imageUrl={beer.imageUrl}
								name={beer.name}
								companyWebsite={beer.companyWebsite}
							/>
						) : (
							<></>
						)
					)}
				</div>
			</div>
			<div className='tableRow'>
				<div tabIndex={1} className='ratingBox box6'>
					F
				</div>
				<div className='beerContainer'>
					{beerList.map((beer) =>
						beer.rank === 'F' ? (
							<BeerItem
								imageUrl={beer.imageUrl}
								name={beer.name}
								companyWebsite={beer.companyWebsite}
							/>
						) : (
							<></>
						)
					)}
				</div>
			</div>
		</div>
	);
};

export default RankingTableContainer;
