import React from "react";
import FooterContainer from "../../containers/footerContainer";
import "./home.css";

const Home = () => {
  return (
    <div className="wrapper">
      <div className="fixed-bg bg-1">
        <h1 tabIndex={1}>Sports Stories You Never Knew Existed</h1>
      </div>

      <div className="scroll-bg">
        <h3 tabIndex={1} style={{ width: "50%" }}>
          The Last Second Podcast. I love sports. My investment has been
          ingrained within me since before I can remember. Celebrating, crying
          in defeat, letting the nerves of a big game change my daily routine,
          all of it has shaped me to be the person I am today… A degenerate
          sports enthusiast who brings up sports facts that nobody ever needed
          or wanted to hear. On my way home from work one day, I thought, “How
          can I do this more often… How can I talk about all these facts, all
          these stories more?” Then… It hit me. I sent a message in my HALO
          Groupchat. “Hey, yall want to do a Sports History Podcast with me? And
          yes we will be drinking.” Even at the last second, little planning, no
          preparation, my three friends, Chase, Jake, and Billy said, “Sure, why
          not.” On its surface, it’s a podcast about crazy stories in sports
          told to my friends for the first time, but at its core… it’s a way for
          me to spend more time with a group of gentlemen who I consider my best
          friends. At the end of the day, all I wanted to do was combine sports
          and take an hour or two each week from my friends' lives to spend time
          listening to me. So, sit back, relax, crack a beer, and enjoy the
          podcast.
        </h3>
      </div>

      <div className="fixed-bg bg-2">
        <h1 tabIndex={1}>Sports Stories That You Never Would Guess Existed</h1>
      </div>

      <div className="scroll-bg">
        <h3 tabIndex={1}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in
          dolor tempor, posuere mi id, eleifend magna. Nulla at lectus magna.
        </h3>
      </div>

      <div className="fixed-bg bg-3">
        <h1 tabIndex={1}>Sports Stories That You Never Would Guess Existed</h1>
      </div>
      <FooterContainer />
    </div>
  );
};

export default Home;
