import React from "react";
import Carousel from "react-multi-carousel";
import "./customCarousel.css";
import MerchItem from "../merchItem/merchItem";
import "react-multi-carousel/lib/styles.css";

const CustomCarousel = ({ merchItems }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: 6,
    },
    inBetweenDeskAndTab: {
      breakpoint: { max: 1000, min: 850 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 850, min: 628 },
      items: 3,
    },
    inBetweenTabAndMob: {
      breakpoint: { max: 628, min: 340 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 340, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel responsive={responsive} containerClass="customCarousel">
      {merchItems.map((item) => {
        return (
          <>
            <MerchItem
              url={item.url}
              merchTitle={item.merchTitle}
              merchPrice={item.merchPrice}
              availability={item.availability}
            />
          </>
        );
      })}
    </Carousel>
  );
};

export default CustomCarousel;
