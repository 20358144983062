import React from 'react';
import './footerContainer.css';
import IconComponent from '../../components/iconComponent';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import PodcastsIcon from '@mui/icons-material/Podcasts';

const FooterContainer = () => {
  const icons = [
    { icon: <YouTubeIcon />, link: 'https://www.youtube.com/' },
    { icon: <FacebookIcon />, link: 'https://www.facebook.com/' },
    { icon: <InstagramIcon />, link: 'https://www.instagram.com/' },
    { icon: <TwitterIcon />, link: 'https://twitter.com/?lang=en' },
    { icon: <PodcastsIcon /> },
  ];
  return (
    <div className="footerContainer">
      {icons.map((item) => (
        <IconComponent icon={item.icon} link={item.link} key={item.link} />
      ))}
    </div>
  );
};

export default FooterContainer;
