import React from "react";
import CustomCarousel from "../../components/carousel";
import "./merchContainer.css";
import MerchItems from "../../data/merch";
const MerchContainer = () => {
  return (
    <div style={{ display: "flex" }}>
      <div className="topBox">
        <div className="topBoxContent">
          <div className="topBoxTitle">Browse New and Upcoming</div>
          <div className="topBoxTitle">Last Second Podcast Merch</div>
          <div className="topBoxSubTitle">
            Shop here for the best discounts on your favorite Last Second
            Podcast Merch
          </div>
        </div>
        <div className="topBoxContentMobile">
          <div className="topBoxTitle">Browse New and Upcoming Merch</div>
          <div className="topBoxSubTitle">
            Shop here for the best discounts on your favorite Last Second
            Podcast Merch
          </div>
        </div>
      </div>
      <div className="bottomBox">
        <CustomCarousel merchItems={MerchItems} />
      </div>
    </div>
  );
};

export default MerchContainer;
