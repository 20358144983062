import React from "react";
import { Tooltip } from "@mui/material";
import "./beerItem.css";

const BeerItem = ({ companyWebsite, imageUrl, name }) => {
  return (
    <Tooltip title={name} placement="top">
      <img
        src={imageUrl}
        alt="Beer Can"
        className="beerImage"
        onClick={() => window.open(companyWebsite, "_blank")}
        tabIndex={1}
      ></img>
    </Tooltip>
  );
};

export default BeerItem;
