import React, { useState } from 'react';
import { Button } from '@mui/material';
import RankingTableContainer from '../../containers/rankingTableContainer';
import AustinBeers from '../../data/austinBeers';
import './rankings.css';
import BillyBeers from '../../data/billyBeers';
import ChaseBeers from '../../data/chaseBeers';
import JakeBeers from '../../data/jakeBeers';

const Rankings = () => {
	const [beerListSelected, setBeerList] = useState('austinBeers');
	return (
		<div className='rankingsContainer'>
			<div className='buttonContainer'>
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						marginBottom: '16px',
					}}
				>
					<p className='beerListTitle'>Select Beer List:</p>
					<Button
						className='austinButton'
						variant='contained'
						tabIndex={1}
						onClick={() => setBeerList('austinBeers')}
					>
						{`Austin`}
					</Button>
					<Button
						className='billyButton'
						variant='contained'
						tabIndex={1}
						onClick={() => setBeerList('billyBeers')}
					>
						{`Billy`}
					</Button>
					<Button
						className='chaseButton'
						variant='contained'
						tabIndex={1}
						onClick={() => setBeerList('chaseBeers')}
					>
						{`Chase`}
					</Button>
					<Button
						className='jakeButton'
						variant='contained'
						tabIndex={1}
						onClick={() => setBeerList('jakeBeers')}
					>
						{`Jake`}
					</Button>
				</div>
			</div>
			{beerListSelected === 'austinBeers' ? (
				<RankingTableContainer beerList={AustinBeers} />
			) : beerListSelected === 'billyBeers' ? (
				<RankingTableContainer beerList={BillyBeers} />
			) : beerListSelected === 'chaseBeers' ? (
				<RankingTableContainer beerList={ChaseBeers} />
			) : (
				<RankingTableContainer beerList={JakeBeers} />
			)}
		</div>
	);
};

export default Rankings;
